<template>
  <div id="settings-modal" class="modal">
    <div class="modal-box ">
      <div class="form-control">
        <label class="label">
          <input type="checkbox" v-model="settings.playMyTurnSound" class="checkbox checkbox-secondary">
          <span class="label-text text-black">{{ $t('settings.play_sound_label')}}</span>
        </label>
        <label class="label">
          <input type="checkbox" v-model="settings.showCellCoord"  class="checkbox checkbox-secondary">
          <span class="label-text">{{ $t('settings.cells_coord_label')}}</span>
        </label>
        <hr>
        <LanguageSwitcher />

      </div>
      <div class="modal-action">
        <a href="#" class="btn">{{ $t('close_btn_title')}}</a>
      </div>
    </div>
  </div>
</template>
<script>
import LanguageSwitcher from '../LanguageSwitcher.vue'
import axios from 'axios'

 import EventBus from '../../../event-bus.js'
export default {
  name: 'ModalSettings',
  components: {
     LanguageSwitcher,
  },
  data: function(){
    return {
      size: 'normal',
      lang: 'en'
    }
  },
  computed: {

  },
  mounted() {
    //updateSquareSize
    console.log('mounted settings');
    console.log(this.user);
  },
  created() {
    EventBus.$on('updateLang', this.updateLang);
  },
  methods: {
    updateSquareSize(size){
      this.size = size
      this.$emit('updateSquareSize', size);
      this.save()
      
    },
    updateLang(lang){
      this.lang = lang
      this.save()
    },
    async save(){


      let pmts = 0
      let scc = 0
      let l = this.lang
      let s = this.size

      if(this.settings.playMyTurnSound){
        pmts = 1
      }
      if(this.settings.showCellCoord){
        scc = 1
      }
      
      if (!this.user.token) {
        return;
      }else{
        console.log('save settings');

      }
        await axios
          .post(this.$backend + '/user/settings/save', 
          { 
            playMyTurnSound: pmts, 
            showCellCoord: scc, 
            lang: l,
            cellSize: s,

          }, 
          {
            headers: {
              Authorization: 'Bearer ' + this.user.token //the token is a variable which holds the token
            }
          })
          .then(response => {
            //если ошибка
            console.log(response);
            EventBus.$emit('checkUserTokenInCookie');
          })
          .catch(error => {
 
            console.log(error.response.status);
            if (error.response.status == 401) {
              this.errorMessage = (error.response.data.message);
              return false;
            }

            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    }
  },
  props: ["settings", "user"]
}
</script>
