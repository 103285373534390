<template>
  <div class="flex justify-center">
    <div class="play ">
      <vue-fab mainBtnColor="#3599DB">
        <fab-item @clickItem="showControlItem('logs')" :idx="1" title="Log" icon="feed" />
        <fab-item @clickItem="showControlItem('settings')" :idx="2" title="Settings" icon="settings" />
        <fab-item @clickItem="showControlItem('leave')" :idx="3" title="Leave" icon="logout" />
      </vue-fab>
      <div class="flex flex-row space-x-0 space-y-0 text-center" v-if="settings.showCellCoord">
        <div  v-bind:class="[squareMainClassRow, setClass()]" class="mt-0">
          
        </div>
        <div v-for="(rv, col) in gameMapSquares" v-bind:key="col" v-bind:class="[squareMainClassRow, setClass()]">
          {{col +1}}
        </div>
      </div>

      <div  v-for="(rv, row) in gameMapSquares" v-bind:key="row" class="flex flex-row space-x-0 space-y-0">
        <div v-if="settings.showCellCoord" v-bind:class="[squareMainClass, setClass()]" class=" mt-0.5">
          {{row +1}}
        </div>
        <Square v-for="(cv, column) in rv"
            v-bind:squareData="cv"
            v-bind:playersColor="playersColor"
            v-bind:players="players"
            v-bind:column="column"
            v-bind:row="row"
            v-bind:key="cv.id"
            v-bind:squareSize="squareSize"
            v-bind:useWeapon="useWeapon"
            v-bind:squareMainClass="squareMainClass"
            class="mt-0"
            @updateSquare="updateSquare"
            />
      </div>
      <div class="text-2xl" v-html="last_log_text"></div>
      <br>
      <Arsenal v-bind:tools="tools"/>
      <br>
      <a
        v-if="gameStatus !== 'Play'"
        href="#join-modal"
        class="flex w-fit h-10 px-4 py-1 text-lg ml-10 mr-10 bg-indigo-500 rounded"
        @click="attemptedJoin = true"
      >
        <svg
          width="35"
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
            clipRule="evenodd"
          />
        </svg>
        <span class="text-white">{{ $t("join_btn_title") }}</span>
      </a>

      <div id="join-modal" class="modal" v-if="!mustBeLogin">
        <div class="modal-box">
          <div class="form-control">
            <div class="game-notice bg-gray-100 dark:bg-gray-800 p-4 border border-gray-300 dark:border-gray-700">
              <h2 class="text-xl font-bold text-gray-900 dark:text-gray-100 mb-2">{{ $t('important_notes_before_join') }}</h2>
              <p class="text-sm text-gray-700 dark:text-gray-300">
                {{ $t('important_text_before_join') }}
              </p>
          </div>

          </div>
          <div class="modal-action">
            <a href="#" @click="joinToGame" class="btn btn-primary">{{
              $t("join_btn_title")
            }}</a>
            <a href="#" class="btn">{{ $t("close_btn_title") }}</a>
          </div>
        </div>
      </div>

      <FullScreen v-if="mustBeLogin && attemptedJoin">
        <div class="text-lg text-black">

        </div>
        <div class="bg-white p-12">
          <div class="text-2xl text-black">{{ $t("playground.must_be_loggin") }}</div>
          <div class="flex items-baseline justify-between">
            <router-link :to="{ name: 'Login', params: { lang: $i18n.locale} }">
              <div class="text-sm text-blue-600 hover:underline">{{ $t("login") }}</div>
            </router-link>
          <div>
            <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale }}">
                <div class="text-sm text-blue-600 hover:underline">{{ $t("back") }}</div>
              </router-link>
            </div>
          </div>
          
        </div>
      </FullScreen>
      <FullScreen v-if="goWin"><Win v-bind:playTime="gameTimeMin" v-bind:playerScore="myScore"></Win></FullScreen>
      <FullScreen v-if="goLose"><Lose v-bind:playTime="gameTimeMin" v-bind:playerScore="myScore"></Lose></FullScreen>

      <FullScreen v-if="playerLeave"><Leave v-bind:user="user" v-bind:gk="gameKey" v-bind:players="players"/></FullScreen>

      <FullScreen v-if="controlItem.players"><PlayersList  v-bind:players="players" v-bind:playersColor="playersColor" /></FullScreen>
      <FullScreen v-if="controlItem.settings"><Settings v-bind:settings="settings" v-bind:squareSize="squareSize"/></FullScreen>
      
      <FullScreen v-if="controlItem.logs"><Logs v-bind:myKey="myKey" 
                                                v-bind:playersColor="playersColor"
                                                v-bind:players="players"/>
                                          </FullScreen>

    </div>
  </div>
</template>

<script>

import EventBus from '../../event-bus.js'
import PlayersList from './Players/List.vue'
import Leave from './Players/Leave.vue'
import Arsenal from './Arsenal/List.vue'
import Logs from './Logs/Main.vue'
import Settings from './Settings/Main.vue'
import Square from './Square.vue'
import axios from 'axios'
import Win from '../UX/GameResult/Win.vue'
import Lose from '../UX/GameResult/Lose.vue'
import FullScreen from '../UX/FullScreen/FullScreen.vue'


export default {
  name: 'PlayGame',
  components: {
    Square, FullScreen, Win, Lose, PlayersList, Arsenal, Settings, Logs, Leave
  },
  mounted() {
    this.getGame();
    this.getMap();
    this.fillPlayersColorArray();
    this.getPlayers();
    EventBus.$emit('updateGameStatus', 'ig');
  },
  props: ["settings", "user"],
  data: function() {
    return {
      gameId: null,
      gameStatus: null,
      gameMapSquares: [],
      playersColor: [],
      playersColorArray: [],
      players: [],
      tools: [],
      squareSize: 'normal',
      me: null,
      myKey: null,
      waitingBuildResult: 0,
      waitingJoinResult: 0,
      isMyTurn: 1,
      myStepsCount: 0,
      myScore: 100,
      goWin: false,
      goLose: false,
      playerLeave: false,
      mustBeLogin: false,
      gameTimeMin: 0,
      useWeapon: false,
      keyWeapon: '',

      squareMainClass: "text-center border-2 flex items-center justify-center cursor-pointer",
      squareMainClassRow: "",
      last_log_text: "waiting players...",
      controlItem: {
        players: false,
        tools: false,
        settings: false,
        logs: false
      },
      attemptedJoin: false,
      username: '',
    }
  },
  created() {
    this.squareSize = this.settings.cellSize
    EventBus.$on('joinToGame', this.joinToGame);
    EventBus.$on('updatePlayersFromSocket', this.getPlayers);
    EventBus.$on('updateSquare', this.updateSquare);
    EventBus.$on('updateSquareFromSocket', this.updateSquare);
    EventBus.$on('gameOver', this.gameOver);
    EventBus.$on('buildHere', this.buildHere);
    EventBus.$on('setWeapon', this.setWeapon);
    EventBus.$on('turnOffWeapon', this.turnOffWeapon);
    EventBus.$on('showControlItem', this.showControlItem);
    EventBus.$on('setSquareSize', this.setSquareSize);
    this.checkCookie();
    this.checkLogin();
  },
  methods: {
    //https://bestringtoness.com/sounds/mp3/icq-uh-oh.mp3
    //http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3
    //
    async checkLogin(){
      await EventBus.$emit('checkUserTokenInCookie');
      console.log('this.user.token');
      console.log(this.user.token);
      if(this.user.token === null){
        console.log('mustBeLogin');
        this.mustBeLogin = true;
        return false
      }
      this.mustBeLogin = false;
      console.log('mustBeLogin: ', this.mustBeLogin);
    },
    showControlItem(controlItemName){
      //this.controlItem.players = !this.controlItem.players;
      this.controlItem.players = false;
      this.controlItem.settings = false;
      this.controlItem.logs = false;
      this.playerLeave = false;

      if(controlItemName == 'home'){
        this.controlItem.logs = false;
        this.controlItem.players = false;

        this.controlItem.settings = false;
      }
      if(controlItemName == 'players'){
        EventBus.$emit('updatePlayers', this.players, this.playersColor)
        this.controlItem.players = true;

        this.controlItem.settings = false;
        this.controlItem.logs = false;
      }

      if(controlItemName == 'settings'){

        this.controlItem.players = false;
        this.controlItem.settings = true;
        this.controlItem.logs = false;
      }
      if(controlItemName == 'logs'){
        this.controlItem.logs = true;

        this.controlItem.players = false;
        this.controlItem.settings = false;
        
      }
      if(controlItemName == 'leave'){

        this.controlItem.players = false;
        this.controlItem.settings = false;
        this.playerLeave = true;
        
      }

    },
    setClass(){
      let cls = null
      cls = "text-pink-500 ";
      cls = cls + "bg-white "+ " border-white";
      if (this.squareSize == 'small') {
        cls = cls + ' '+' text-xs h-5 w-5 font-light'
      }else if(this.squareSize == 'normal'){
        cls = cls + ' '+' text-xl h-7 w-7 '
      }else if(this.squareSize == 'big'){
        cls = cls + ' '+' text-xl h-10 w-10 '
      }else if(this.squareSize == 'huge'){
        cls = cls + ' '+' text-2xl h-16 w-16 '
      }

      return cls;
    },
    setWeapon(key){
      console.log('set tool');
      this.useWeapon = !this.useWeapon;
      this.keyWeapon = key;

    },
    setSquareSize(size) {
      this.squareSize = size
      console.log('setSquareSize: '+ size);
     EventBus.$emit('updateSquareSize', size);
    },
    turnOffWeapon(){
      console.log('turn off tool');
      this.useWeapon = false;
      this.keyWeapon = '';

    },
    async buildHere(square_id){
      if(this.waitingBuildResult == 1){
        return false;
      }
      console.log('useWeapon: ' + this.useWeapon);
      if (this.useWeapon) {
        return this.shotHere(square_id, this.keyWeapon);
      }

      if(this.myStepsCount >= 1){
        if(this.isMyTurn == 0){
          this.last_log_text = '❌ '+ this.$t('notify_is_not_your_turn')
          return false;
        }
      }
      this.waitingBuildResult = 1;
      await axios
          .post(this.$backend + '/game/build/set', { id: square_id, owner: this.myKey })
          .then(response => {
            //если ошибка

            if(typeof response.data.m !== 'undefined'){
              this.$notify({
                group: 'default',
                type: 'warn',
                //title: 'Important message',
                text: response.data.m,
                position: "bottom"
              });
              this.waitingBuildResult = 0;
              return false;
            }

            this.last_log_text = this.$t('notify_successfully_built')

            if (response.data.g == 1) {
              EventBus.$emit('gameOver');
            }
            this.waitingBuildResult = 0;
            this.myStepsCount = this.myStepsCount + 1;
          })
          .catch(error => {
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error,
              position: "bottom"
            });
            this.waitingBuildResult = 0;
          });
    },
    async shotHere(square_id, keyWeapon){

      await axios
          .post(this.$backend + '/game/build/weapon/set', { id: square_id, owner: this.myKey, wk:  keyWeapon})
          .then(response => {
            //если ошибка

            if(typeof response.data.m !== 'undefined'){
              this.$notify({
                group: 'default',
                type: 'warn',
                //title: 'Important message',
                text: response.data.m,
                position: "bottom"
              });
              return false;
            }
            this.useWeapon = false;
            this.keyWeapon = '';
            this.last_log_text = this.$t('notify_tool_successfully_used')

            this.$socket.emit('updateSquare');
            EventBus.$emit('updateSquare');
            EventBus.$emit('turnOffWeapon');
            EventBus.$emit('updateWeapon');
            if (response.data.g == 1) {
              EventBus.$emit('gameOver');
            }
          })
          .catch(error => {
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error,
              position: "bottom"
            });
          });
      return true;
    },
    async checkCookie(){
        var pk = this.$cookie.get('playerId');
        var gk = this.$cookie.get('gk');
        if (pk == null) {
          console.log('no pk');
          return false;
        }
        if (gk == null) {
          console.log('no gk');
          return false;
        }
        await axios
          .post(this.$backend + '/game/player/check', { gk:gk, key: pk })
          .then(response => {
            if (response.data.status == 'ok') {
              this.myKey = pk;
              this.me = response.data.player.id;
              EventBus.$emit('successJoinToGame', {name: response.data.player.name, id: response.data.player.id, key: response.data.player.key });
              this.$socket.emit('joinToGame', {game_room: this.gameId});
              this.updateSquare();
            }else{
              this.$cookie.delete('playerId');
              this.$cookie.delete('gk');
            }
          })
          .catch(error => console.log(error));
    },
    playSound (sound = 'https://app.insquare.online/sounds/sci-fi.wav') {
      if (this.settings.playMyTurnSound) {
        if(sound) {
          var audio = new Audio(sound);
          audio.play();
        }
      }
    },
    getTools() {
      axios
        .post(this.$backend + '/game/player/arsenal/get', { game_id: this.gameId, key: this.myKey })
        .then(response => {
            if(typeof response.data.m !== 'undefined'){
              return false;
            }else{
              this.tools = response.data;
            }
        })
        .catch(error => console.log(error));
    },
    getGame(needCheckCookie = true) {
      this.gameKey = this.$route.params.id;
      axios
        .post(this.$backend + '/game/get', { gk: this.gameKey, key: this.myKey })
        .then(response => {
          this.gameId = response.data.id
          if(needCheckCookie){
            this.checkCookie();
          }
        })
        .catch(error => console.log(error));
    },
    getGameStatus() {
      console.log('check game status');
      axios
        .post(this.$backend + '/game/status/get', { gk: this.gameKey })
        .then(response => {
          console.log(response.data.s);

            if((response.data.s == 'Play') && this.gameStatus != 'Play'){
              this.$notify({
                group: 'default',
                type: 'success',
                text: this.$t('notify_game_has_started'),
              });
            }

            if((response.data.s == 'Game Over')){
              EventBus.$emit('gameOver');
            }
          this.gameStatus = response.data.s;
        })
        .catch(error => console.log(error));
    },
    getMap(){
        axios
          .post(this.$backend + '/game/map/get', { gk: this.gameKey })
          .then(response => {
            this.gameMapSquares = response.data.map;
          })
          .catch(error => console.log(error));
      },

      getQueue(){
        console.log('++++++++++getQueue+++++++++');
        this.isMyTurn = 1;
        axios
          .post(this.$backend + '/game/queue/get', { game_id: this.gameId, key: this.myKey })
          .then(response => {
            EventBus.$emit('setQueue', response.data);

            if(typeof response.data.m !== 'undefined'){
              this.$notify({
                group: 'default',
                type: 'warn',
                text: response.data.m,
                position: "bottom"
              });
              this.isMyTurn = 1;
              return false;
            }

            this.players.forEach((item) => {
              if(response.data.player_id == item.id){
                this.last_log_text = this.$t('notify_next_turn', {name: item.name})
              }
            });

            if(this.me == response.data.player_id){
              this.playSound();
            }else{
              this.isMyTurn = 0;
              
            }
           
          })
          .catch(error => console.log(error));
      },
      fillPlayersColorArray(){
        this.playersColorArray[1] = {color: "indigo-700"}
        this.playersColorArray[2] = {color: "red-500"}
        this.playersColorArray[3] = {color: "yellow-500"}
        this.playersColorArray[4] = {color: "green-500"}
      },
      setPlayersColor(playerId, playerNumber){
        this.playersColor[playerId]=this.playersColorArray[playerNumber];
      },
      getPlayers(){
        if(this.gameId == null){
          return false;
        }
        axios
          .post(this.$backend + '/game/player/get', { game_id: this.gameId, 'key': this.myKey })
          .then(response => {
            this.players = response.data;
            this.players.forEach((item) => {
              this.setPlayersColor(item.id, item.n)
              if(item.id == this.me){
                this.myScore = item.f;
              }
            });
            EventBus.$emit('updatePlayers', this.players, this.playersColor);
            
          })
          .catch(error => console.log(error));

      },
      getGameTime(){
        axios
          .post(this.$backend + '/game/time/get', { 'key': this.gameKey })
          .then(response => {
            this.gameTimeMin = response.data.gt_min
          })
          .catch(error => console.log(error));

      },
      gameOver() {
        this.getGameTime();
        var imwin = false;
        this.gameStatus = 'Game Over';
        axios
          .post(this.$backend + '/game/player/who/win', { game_id: this.gameId, 'key': this.myKey })
          .then(response => {

            if(response.data.id == this.me){
              imwin = true;
              if(imwin){
                this.goWin = true;
              }else{
                this.goLose = true;
              }
            }

            this.$cookie.delete('playerId');
            this.$cookie.delete('gk');
          })
          .catch(error => console.log(error));

      },
      updateSquare(){
        this.getQueue();
        this.getMap();
        this.getPlayers();
        this.getGameStatus();
        this.getTools(); 
        EventBus.$emit('updateWeapon', this.myKey);
      },
      updateMe(data){
        this.myKey = data.player.key
        this.$cookie.set('playerId', data.player.key, { expires: '3h' });
        this.$cookie.set('gk', this.gameKey, { expires: '3h' });

      },
      async joinToGame(username){
        this.attemptedJoin = true;
        if (username == undefined) {
          return false;
        }
        if (username == '') {
          return false;
        }
        if (this.waitingJoinResult == 1) {
          return false;
        }
        if (this.user.token == null) {
          return false;
        }

        if (this.user.token == undefined) {
          return false;
        }

        if (this.user.token == '') {
          return false;
        }

        this.waitingJoinResult = 1;
        await axios
          .post(this.$backend + '/game/player/join', { game_id: this.gameId, name: username}, {
            headers: {
              Authorization: 'Bearer ' + this.user.token //the token is a variable which holds the token
            }
          }
          )
          .then(response => {
            if(typeof response.data.m !== 'undefined'){
              this.$notify({
                group: 'default',
                type: 'warn',
                //title: 'Important message',
                text: response.data.m
              });
              this.waitingJoinResult = 0;
              return false;
            }
            this.me = response.data.player.id;
            this.updateMe(response.data);
            EventBus.$emit('successJoinToGame', {name: response.data.player.name, id: response.data.player.id, key: response.data.player.key });
            this.$socket.emit('joinToGame', {game_room: this.gameId});
            this.$cookie.set('username', username, { expires: '1Y' });

            this.getPlayers();
            this.waitingJoinResult = 0;
          })
          .catch(error => {

            console.log('----->'+error)
            if (error.response.status == 401) {
              this.mustBeLogin = true
              return false;
            }

            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });

      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.play{
  margin-left: auto;
  margin-right: auto;
}

</style>
