<template>
  <div v-on:click="clickWeapon()" v-bind:class="[setClass(), 'flex', 'items-center', 'max-w-sm', 'rounded', 'overflow-hidden', 'shadow-lg', 'm-5']">
    <div v-bind:class="['w-1/4', 'bg-white', 'h-20', 'flex', 'items-center', 'justify-center']">
      <img :src="img_filename" class="h-full" alt="">
    </div>
    <div class="w-1/2">
      <div class="font-bold text-lg mb-1">{{weapon.name}}</div>
      <p class="text-gray-700 text-xs">
        <span v-html="weapon.description"></span>
      </p>
    </div>
  </div>
</template>

<script>
import EventBus from '../../../event-bus.js'
export default {
  name: 'Weapon',
  data: function(){
    return {
      useIt: false,
      img_filename: null
    }
  },
  computed: {

  },
  created() {

    EventBus.$on('turnOffWeapon', this.turnOffWeapon);
    EventBus.$on('setWeapon', this.checkThisWeapon);
  },
  methods: {
    clickWeapon(){
      if(this.weapon.was_used == 1){
        return false;
      }
      this.useIt = !this.useIt;
      if (this.useIt) {
        EventBus.$emit('setWeapon', this.weapon.key);
      }
    },
    turnOffWeapon(){
      //EventBus.$emit('turnOffWeapon');
      this.useIt = false;

    },
    checkThisWeapon(key){
      if(this.weapon.key != key){
        this.useIt = false;
      }

    },
    setClass(){
      this.img_filename = require("../../../assets/img/weapon/" + this.weapon.weapon_info.filename);
      if(this.weapon.was_used == 1){
        return "bg-gray-400"
      }
      if (!this.useIt) {
        return "bg-green-400"
      }else{
        return "bg-red-400"
      }
      //alert(this.weapon.key);
    }
  },
  props: ['weapon']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
