<template>
  <div class="">
    <div class="flex flex-nowrap ml-24  space-x-4 overflow-auto">
      <Weapon v-for="(weapon) in tools" v-bind:weapon="weapon" v-bind:key="weapon.id" />
    </div>
  </div>
</template>
<script>
import Weapon from '../../Game/Arsenal/Weapon.vue'
import EventBus from '../../../event-bus.js'
export default {
  name: 'Arsenal',
  components: {
    Weapon
  },
  data: function(){
    return {
      arsenal: [],
      listData: null,
      drawerShow: false,
      colors: ['bg-green-200', 'bg-purple-400', 'bg-purple-200','bg-blue-200', 'bg-indigo-500', 'bg-purple-400', 'bg-pink-400', 'bg-red-500', 'bg-black', 'bg-yellow-300']
    }
  },
  computed: {

  },
  mounted() {

  },
  created() {

  },
  methods: {
    closeSlot() {
      EventBus.$emit('showControlItem', 'home');
    },
    tog() {
      this.drawerShow = !this.drawerShow;
    }
  },
  props: {
    tools: Array
  }
}
</script>
