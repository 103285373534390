<template>
    <div class="flex items-center justify-center min-h-screen ">
        <div class="px-8 py-6 mt-4 text-left  shadow-lg border-2">
          <div class="w-full flex justify-end ">
            <div class="item-right ">
              <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale }}">
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"/></svg>
              </router-link>
            </div>
          </div>
            <h3 class="text-2xl font-bold text-center">{{ $t("restore_pass_enter_email.form_title") }}</h3>
            <div v-if="(!success_message && !noemail_message)">
              <div>
              <small>{{ $t("restore_pass_enter_email.sub_title") }}</small>
            </div>
            <div>
                <div class="mt-4">

                    <div>
                        <label class="block" for="email">Email</label>
                                <input type="text" placeholder="" v-model="email" name="email"
                                    class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 text-black">
                    </div>
                    <div class="flex items-baseline justify-between">
                      <router-link :to="{ name: 'Login', params: { lang: $i18n.locale} }">
                        <div class="text-sm text-blue-600 hover:underline">{{ $t("login") }}</div>
                      </router-link>
                      <button @click="check" class="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">{{ $t("restore_pass_enter_email.button_title") }}</button>
                    </div>
                </div>
            </div>
            </div>
            <div class="mt-10 mb-10" v-if="success_message">
              <span class="text-green-500">{{ $t("restore_pass_enter_email.success_message") }}</span>
              <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale} }">
                <div  class="text-big text-center mt-5 text-blue-600  hover:underline">{{ $t("back") }}</div>
              </router-link>
            </div>
            <div class="mt-10 mb-10" v-if="noemail_message">
              <span class="text-big text-center mt-5 text-red-600">{{ $t("restore_pass_enter_email.noemail_message") }}</span>
              <div  @click="showForm" class="text-big text-center mt-5 text-blue-600  hover:underline">{{ $t("try_agan") }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'NewPassEnter',
  components: {

  },
  data: function(){
    return {
      email: null,
      noemail_message: false,
      success_message: false
    }
  },
  computed: {

  },
  mounted() {
    document.title = "In Square Game. Restore password form";
  },
  created() {

  },
  methods: {
    showForm(){
      this.success_message = false;
      this.noemail_message= false;
    },
    check(){
      // if( !this.validateForm() ) {
      //   return false;
      // }
      if((this.email === null) || (this.email == '')){
        this.errorMessage = 'fill in the Email field'
        this.hasError = true
      }

      this.success_message = false;
      this.noemail_message= false;

      if(this.hasError){
        return false;
      }
      axios
          .post(this.$backend + '/up/restore/check', { email: this.email, lang: this.$i18n.locale})
          .then(response => {
            //если ошибка
            console.log(response.data);
            if(response.data.success == "ok") this.success_message = true;
            if(response.data.m == "no_email") this.noemail_message = true;
          })
          .catch(error => {
            console.log(error);
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    },
    validateForm(){
      // if(this.confirmPassword !== this.password){
      //   console.log('wrong pass');
      //   return false
      // }
    }
  },

  props: []
}
</script>
